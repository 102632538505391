.words__title {
  font-size: 34px;
}

.skip-word {
  font-size: 18px;
  color: #ff2b62;
}

.btn-skip__container {
  text-align: left;
}

.btn-skipword {
  background: #3756e9 !important;
  color: #fff;
  background-color: #3756e9 !important;
  border: 1px solid #3756e9 !important;
  font-size: 16px;
  font-weight: bold;
  width: 110px;
  height: 45px;
  max-width: 100%;
  max-height: 42px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .btn-skip__container {
    margin-top: 1rem;
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
