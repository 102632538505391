.btn-selectOption {
  background-color: #e2e2e2;
  border: 1px solid #c6c6c6;
  border-radius: 28px;
  font-size: 24px;
  color: #0d2b47;
}

.btn-selectOption:focus,
.btn-selectOption:hover,
.btn-selectOption:active {
  background-color: #3756e9;
  color: #ffffff;
}
