.box-thankyou {
  border: 5px solid #f3e6e6;
  background-color: #f6efef;
  padding-top: 10%;
  padding-bottom: 10%;

  .box-thankyou-title {
    text-transform: uppercase;
    color: #ff2b62;
    font-size: 0.7em;
    font-weight: bold;
    word-break: break-word;
  }
}

.btn-add-more-friends {
  display: flex;
  align-items: center;
  background-color: unset !important;
  border-color: unset !important;
  border: unset !important;
  color: #ff2b62 !important;
  box-shadow: unset !important;

  &:focus,
  .focus,
  &:hover {
    box-shadow: unset !important;
    text-decoration: underline;
  }

  & > img {
    width: 30%;
  }
}

.text-large {
  font-size: 3em;
}

.text-medium {
  font-size: 2em;
}

.text-small {
  font-size: 1em;
}

.input-lastname {
  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.input-email-container {
  // padding-left: 12px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .p-xs-0 {
    // padding: 0 !important;
  }

  .p-xs-1 {
    padding: 0.75rem !important;
  }

  .p-xs-2 {
    padding: 1rem !important;
  }

  .p-xs-3 {
    padding: 1.25rem !important;
  }

  .p-xs-4 {
    padding: 1.5rem !important;
  }

  .text-large {
    font-size: 2em;
  }

  .text-medium {
    font-size: 1em;
  }

  .text-small {
    font-size: 0.5em;
  }

  .result-container {
    margin-bottom: 0 !important;
    padding: 8px;
  }

  .referral-container{
    padding: 8px;
  }

  .input-email-container {
    padding-left: 0 !important;
  }

  .msg-thankyou-referral {
    height: 0;
  }
}

@media only screen and (max-width: 400px) {
}
