.navbar-custom {
  background-color: #fff !important;
}

.navbar-brand {
  margin-right: 0 !important;
}

.navbar-expand {
  justify-content: center !important;
}

.text-container {
  padding: 0;
}

.input-container {
  padding: 0;
}

.btn-AdsPopup {
  height: 80px;
  width: 285px;
}

.modal-dialog {
  padding-left: 35%;
  padding-right: 35%;
}

.modal-title {
  font-size: 1rem !important;
}

.modal-body > p {
  font-size: 1.25rem !important;
}

.modal-header .close {
  font-size: 3.5rem;
  padding: 0;
  margin: -1rem -6rem -1rem auto;
}
