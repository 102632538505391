* {
  font-family: "Montserrat", sans-serif;
}

body {
  overflow: auto;
  height: 100vh;
}

#root {
  height: inherit;
}

.cover-container {
  height: 100vh;
}

.header {
  position: relative;
  z-index: 1000;
}

.main-container {
  height: 100vh;
}

.navbar {
  background-color: #0d2b47 !important;
}

.vertical-align-middle {
  margin: auto;
  width: 50%;
}

.custom-navbar {
  padding-left: 10%;
  padding-right: 10%;
}

.container-fluid {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-toggle-blocks-left {
  order: 1;
}

.navbar-toggle-blocks-right {
  flex-grow: 2;
  order: 2;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-unset {
  width: unset;
}

.mw-unset {
  max-width: unset;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
  .custom-navbar {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .custom-navbar {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .custom-navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .content {
    // margin: 8px !important;
  }
}

@media only screen and (max-width: 400px) {
}
