.hearing-loss__container {
  border: 3px solid #0da7f8;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 35%;
}

.phonetically-words__container {
  border: 3px solid #ff0000;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 35%;
}

.chartjs-render-monitor {
  height: 350px !important;
}

@media only screen and (max-width: 400px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .report__container {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 1rem;
  }

  .hearing-loss__container {
    border: 3px solid #0da7f8;
    margin-top: 1rem;
    padding: 0.5rem;
    width: 70%;
  }

  .phonetically-words__container {
    border: 3px solid #ff0000;
    margin-top: 1rem;
    padding: 0.5rem;
    width: 70%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}
