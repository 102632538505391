@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

@font-face {
  font-family: "Helvetica-Bold";
  src: url("/assets/fonts/Helvetica-Bold-Font.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("/assets/fonts/Montserrat-Medium.ttf") format("ttf");
  font-weight: medium;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}
