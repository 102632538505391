.nav__container {
  margin-bottom: 15px;
  text-align: center;
}

.nav__container > .dot {
  display: inline-block;
  display: -webkit-inline-box;
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.nav__container > .dot.active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0px 8px;
}
