.btn-thankYou-next {
  border-radius: 28px;
  height: 65px;
  max-height: unset;
  width: 280px;
  max-width: unset;
}

.msg-thankyou-referral {
  color: #ff2b62;
  font-size: 32px;
}
