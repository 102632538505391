.playCircleIcon {
  width: 80% !important;
  min-width: 30px;
  min-height: 30px;
  max-width: 200px;
  max-height: 200px;
  width: 50% !important;
  height: 100% !important;
  color: #fff;
}
