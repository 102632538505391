/** Import everything from autoload */
@import "autoload/bootstrap";

@import "common/fonts";
@import "common/variables";

@import "common/global";
@import "common/controls";

@import "components/header";
@import "components/dotNav";
@import "components/actionButton";
@import "components/backButton";
@import "components/nextButton";
@import "components/playAudio";
@import "components/maxVolumeModal";
@import "components/stepText";
@import "components/stepTitle";
@import "components/errorMessage";
@import "components/modal";
@import "components/inputText";
@import "components/disclaimer";
@import "components/volumeUpIcon";
@import "components/playIcon";
@import "components/volumeControl";
@import "components/referralForm";

@import "layouts/wizardSteps";

@import "pages/home";
@import "pages/instructions";
@import "pages/contactForm";
@import "pages/step";
@import "pages/words";
@import "pages/hearingTestStepWizard";
@import "pages/phoneticallyWords";
@import "pages/result";
@import "pages/report";
@import "pages/volumeSetting";
@import "pages/thankYou";


@import "responsive";

// If you want to add something do it here
@import "custom";
